import React from "react";
import ctl from "@netlify/classnames-template-literals";

import { Br, Container, Text } from "components";
import { StaticImage } from "gatsby-plugin-image";

import Utilities from "svgs/utilities.svg";
import CardIssuance from "svgs/card-issuance.svg";
import Payments from "svgs/payments.svg";
import VirtualEWallets from "svgs/virtual-e-wallets.svg";

const HomepageOfferings = () => {
  return (
    <section className={offeringsWrapperStyle}>
      <Container>
        <Text
          value={
            <>
              Stay in control of your finances with our <Br on="desktop" />
              innovative digital payment solutions and agency banking services
            </>
          }
          variant="h2"
          color="secondary-1200"
        />
        <Text
          value={
            <>
              Enjoy access to reliable services to help your daily life.
              <Br on="desktop" />
            </>
          }
          variant="p18"
          color="secondary-1200"
        />

        <div className={offeringGridStyle}>
          <div className="max-w-[527px]">
            <StaticImage
              src="../../../assets/images/jpegs/home/young-woman-smiling.jpeg"
              alt="Young woman smiling pressing keyboard"
            />
          </div>
          <div>
            {offerings.map((offering, index) => {
              return (
                <div
                  key={`offering_item_${index}`}
                  className={offeringItemStyle}
                >
                  <span
                    className={`${offeringIconStyle} ${offering?.background}`}
                  >
                    {offering?.icon}
                  </span>
                  <div>
                    <Text
                      variant="h4"
                      value={offering?.title}
                      color="secondary-1200"
                    />
                    <Text
                      variant="p18"
                      value={offering?.description}
                      color="secondary-1200"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

const offeringsWrapperStyle = ctl(`
md:py-[117px]
py-[44px]
`);

const offeringGridStyle = ctl(`
grid
lg:grid-cols-2
grid-cols-1
pt-[81px]
lg:gap-[105px]
gap-[36px]
`);

const offeringIconStyle = ctl(`
w-[81px]
h-[68px]
inline-flex
items-center
justify-center
mr-8
shrink-0
`);
const offeringItemStyle = ctl(`
lg:mb-[60px]
mb-[32px]
last:mb-0
flex
`);

const offerings = [
  {
    title: "E-wallets",
    description:
      "Manage your finances from a digital wallet, allowing you to transfer and receive funds swiftly.",
    icon: <VirtualEWallets className="w-[38px]" />,
    background: "bg-primary",
  },
  {
    title: "Payments",
    description:
      "Perform payment for goods and services via our various payment options, across different financial institutions in Nigeria with ease.",
    icon: <Payments />,
    background: "bg-alternative-200",
  },
  {
    title: "Card Issuance",
    description:
      "Own customized physical cards (Mastercard, VISA or Verve) that are valid for all transactions.",
    icon: <CardIssuance />,
    background: "bg-secondary-300",
  },
  {
    title: "Utilities",
    description:
      "Settle your daily bills like cable TV, electricity, School fees, and more seamlessly across various providers.",
    icon: <Utilities />,
    background: "bg-primary-100",
  },
];

export { HomepageOfferings };
