import { Br, Button, Container, Text, Tabs } from "components";
import React, { useState } from "react";
import Typing from "react-typing-animation";
import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";
import Zoom from "react-reveal/Zoom";
import { useEffect } from "react";
import ParkwayProd from "svgs/parkway.svg";
import AgencyProd from "svgs/Agency-product.svg";
import CBSMockup from "svgs/cbs-mockup.svg";

const HomepageFeatures = () => {
  return (
    <div className={homepageFeaturesWrapperStyle}>
      <Container>
        <Text
          variant="h2"
          value={
            <>
              Move your finances from Routine <Br on="desktop" /> to Remarkable
            </>
          }
          color={textColor}
          className="mb-6"
        />
        <Text
          variant="p18"
          value={
            <>
              Discover how our digital solutions and agency banking network can
              make money management more convenient. <Br on="desktop" />
            </>
          }
          color={textColor}
          className="mb-[33px]"
        />
        <Tabs data={features} />
      </Container>
    </div>
  );
};

const Feature = ({ heading, description, button, codeBlock, animatedCode }) => {
  return (
    <section className={featureSectionWrapper}>
      <div className={featureContentWrapperStyle}>
        <Text
          variant="h3"
          value={heading}
          color={textColor}
          className="mb-[26px]"
        />
        <Text
          variant="p21"
          value={description}
          color={textColor}
          className="md:mb-[68px] mb-8"
        />
        <Button {...button} variant="secondary-light" size="medium" hasArrow />
      </div>

      <div className="mt-4">
        {heading.toLowerCase() === "parkway wallet" ? (
          <ParkwayProd />
        ) : heading.toLowerCase() === "agency banking" ? (
          <AgencyProd />
        ) : heading.toLowerCase() === "e-invoicing" ? (
          <CBSMockup />
        ) : (
          ""
        )}
      </div>
      {/* <StaticImage 
        src="../../../assets/images/pngs/home/parkway.png" 
      /> */}
      {/* <CodeBlockDisplay codeBlock={codeBlock} /> */}
    </section>
  );
};

// const CodeBlockDisplay = ({ codeBlock }) => {
//   const [startTyping, setStartTyping] = useState(false);
//   return (
//     <Zoom onReveal={() => setStartTyping(true)}>
//       <div className={codeImageWrapperStyle}>
//         <pre className={codeStyle.wrapper}>
//           <code>{startTyping && <Typing speed={0.1}>{codeBlock}</Typing>}</code>
//         </pre>
//       </div>
//     </Zoom>
//   );
// };

const textColor = "secondary-100";
const homepageFeaturesWrapperStyle = ctl(`
bg-secondary-1200
md:pt-[120px]
pt-[36px]
md:pb-[51px]
pb-[44px]
text-secondary-100
`);

const featureSectionWrapper = ctl(`
grid
md:grid-cols-2
flex-col
justify-between
items-center
overflow-x-hidden
`);

const featureContentWrapperStyle = ctl(`
max-w-[418px]
md:py-0
py-[40px]
`);

const codeImageWrapperStyle = ctl(`
bg-secondary
rounded
md:py-[26px]
py-[18px]
min-h-[700px]
`);

const codeStyle = {
  wrapper: `break-all overflow-x-hidden text-[14px] bg-secondary p-4 rounded mt-4`,
  variable: `text-primary`,
  function: `text-orange-300`,
  object: ``,
  comment: `text-green-600`,
};

const features = [
  {
    title: "Parkway Wallet",
    content: (
      <Feature
        heading="Parkway Wallet"
        description="A mobile application that provides access to send and receive money to any bank in Nigeria, pay bills, and create virtual cards for online paymentsless transactions anytime, anywhere."
        button={{
          text: "Get Started",
          to: "https://wallet.parkway.ng/#download-box",
        }}
      />
    ),
  },
  {
    title: "Agency Banking",
    content: (
      <Feature
        heading="Agency Banking"
        description="We offer comprehensive agency banking services that enhance financial inclusivity by providing convenient payment and banking solutions to users across the country."
        button={{
          text: "Get Started",
          to: "https://readycash.parkway.ng/",
        }}
      />
    ),
  },
  {
    title: "E-invoicing Services",
    content: (
      <Feature
        heading="e-Invoicing"
        description="Align and classify revenue collections using a string of NUBAN-compliant 10-digits."
        button={{ text: "Start your launch process", to: "#" }}
      />
    ),
  },
];

// const features = [
//   {
//     title: "Payments",
//     content: (
//       <Feature
//         heading="Payments"
//         description="Make transactions easy with a collection of B2B and B2C payment solutions
//   optimized for the different experiences that your clients/customers may have."
//         button={{ text: "Start your launch process", to: "/get-demo" }}
//         codeBlock={
//           <>
//             <span className={codeStyle.variable}>const payment =</span>{" "}
//             <span className={codeStyle.function}>Bank3D.createPayment</span> (
//             {`
//   {
//     "reference":"yourReference",
//     "currencyCode":"NGN",
//     "merchantKey":"{merchantKey}",
//     "amount":5000000,
//     "email":merchant@customer.com,
//     "phone":"{customerPhone}",
//     "color":"#aa0066",
//     "mode":"live",
//     "onReady": function(){`}
//             <p className={codeStyle.comment}>
//               {`      // This popup's iframe has loaded and it's preloader is visible`}
//             </p>
//             <p className={codeStyle.comment}>
//               {`      // Use this to stop you own preloader`}
//             </p>
//             {`        },
//     "onClose": function(){`}
//             {`},
//     "callback": function(reference){`}
//             <p
//               className={codeStyle.comment}
//             >{`        // Transaction was completed and it was successful`}</p>
//             <p
//               className={codeStyle.comment}
//             >{`        // This is the stage where it's necessary to verify
//             the payment using the "reference" argument`}</p>
//             {`      }
//   })
//             `}
//             <br />
//             payment.open()
//           </>
//         }
//         animatedCode
//       />
//     ),
//   },
//   {
//     title: "Collections",
//     content: (
//       <Feature
//         heading="Collections"
//         description="Get access to simple and easy to integrate online payment gateway with
//         support from multiple channels like cards, bank transfers, USSD, QR, Wallets etc.
//         "
//         button={{ text: "Start your launch process", to: "/get-demo" }}
//         codeBlock={
//           <>
//             <span className={codeStyle.function}>
//               $.post("collect/initiatePayment",{`{`}
//             </span>
//             {`
// {
//   "billPaymentProductId":"1234567890",
//   "amount":29908.75,
//   "name":"ADEOLA OBINNA ADAMU",
//   "email":"adeolaobinna@adamu.com",
//   "phoneNumber":"0803XXXXXXX",
//   "transactionRef":"00000000TXXX000011",
//   "customerId":"XXXXXX",
//   "customFields": {"lgr":"LCDA YABa"}
// }, (response, status) => {
//   `}{" "}
//             <p className={codeStyle.comment}>
//               {`// Payment Initiation succeeded or failed`}
//             </p>
//             {`}
// });
// `}
//           </>
//         }
//       />
//     ),
//   },
//   {
//     title: "e-Invoicing",
//     content: (
//       <Feature
//         heading="e-Invoicing"
//         description="An easy way to align and classify collections for your business
//         using one string of NUBAN compliant 10-digits. "
//         button={{ text: "Start your launch process", to: "/get-demo" }}
//         codeBlock={
//           <>
//             <span className={codeStyle.function}>
//               $.post("virtual_acount/create",{`{`}
//             </span>
//             {`
// {
//   "account_name":"{merchantName}",
//   "ttl":300,
//   "external_ref":"0000000000X001",
//   "multi":false,
//   "amount":"35122.50",
//   "exact_amount":true,
// }, (response, status) => {
//   `}{" "}
//             <p className={codeStyle.comment}>
//               {`// Invoice creation succeeded or failed`}
//             </p>
//             {`}
// });
// `}
//           </>
//         }
//       />
//     ),
//   },
//   {
//     title: "Agency Banking",
//     content: (
//       <Feature
//         heading="Agency Banking"
//         description="Our APIs are an easy way to build solutions that expand the frontiers of financial
//         inclusivity with innovations that make last-mile banking services available for users across the country
//        "
//         button={{ text: "Start your launch process", to: "/get-demo" }}
//         codeBlock={
//           <>
//             <span className={codeStyle.function}>
//               $.post("wallet/create",{`{`}
//             </span>
//             {`
// {
// "firstName":"OBINNA",
// "lastName":"ADAMU",
// "address":"No 5 Arwolo street",
// "email":"adeolaobinna@adamu.com",
// "bvn":"000000000000",
// "businessName":"OBINNA ADAMU MOBILE KIOSK",
// "phoneNumber":"0803xxxxxxx",
// "state":"Lagos",
// "localGovt":"Mushin",
// "corporate":true,
// "app":"My Agent App"
// }, (response, status) => {
// `}{" "}
//             <p className={codeStyle.comment}>
//               {`// Payment initiation succeeded or failed`}
//             </p>
//             {`}
// });
// `}
//           </>
//         }
//       />
//     ),
//   },
//   {
//     title: "Identity Verification",
//     content: (
//       <Feature
//         heading="Identity Verification"
//         description="You can use our APIs to personalize and enhance the onboarding experience
//         for your customers with a wide range of validation services that includes BVN, NIN, FRSC etc.
//        "
//         button={{ text: "Start your launch process", to: "/get-demo" }}
//         codeBlock={
//           <>
//             <span className={codeStyle.function}>
//               $.post("identity/nin/verify",{`{`}
//             </span>
//             {`
// {
// "nin":"00000000000",
// }, (response, status) => {
// `}{" "}
//             <p className={codeStyle.comment}>
//               {`// Identity verification succeeded or failed`}
//             </p>
//             {`}
// });
// `}
//             <br />
//             <span className={codeStyle.function}>
//               $.post("identity/bvn/verify",{`{`}
//             </span>
//             {`
// {
// "nin":"00000000000",
// "dateOfBirth":"mm/dd/yyyy"
// }, (response, status) => {
// `}{" "}
//             <p className={codeStyle.comment}>
//               {`// Identity verification succeeded or failed`}
//             </p>
//             {`}
// });
// `}
//             <br />
//             <span className={codeStyle.function}>
//               $.post("bank/account/verify",{`{`}
//             </span>
//             {`
// {
// "nin":"0000000000",
// "bankCode":"000"
// }, (response, status) => {
// `}{" "}
//             <p className={codeStyle.comment}>
//               {`// Identity verification succeeded or failed`}
//             </p>
//             {`}
// });
// `}
//           </>
//         }
//       />
//     ),
//   },
// ];

export { HomepageFeatures };
