import * as React from "react";

import { Clients, Layout } from "components";
import { HomePageHeader, HomepageOfferings } from "templates/home";
import { HomepageFeatures } from "templates/home/features";

const IndexPage = () => (
  <Layout title="Parkway - Simple Payment Infrastructure" ignoreSiteName={true}>
    <HomePageHeader />
    <HomepageOfferings />
    <HomepageFeatures />
    {/* <Clients /> */}
  </Layout>
);

export default IndexPage;
