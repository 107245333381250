import { Br, PageHeader } from "components";
import React from "react";
import TextAnimation from "react-text-animations";

const HomePageHeader = () => {
  return (
    <PageHeader
      title={
        // <>
        //   Creating a Timeless <Br on="desktop" />
        //   Financial Experience <Br on="desktop" />
        //   <span className="text-primary">
        //     <TextAnimation.Slide
        //       target="Banking"
        //       text={["Financial", "Banking"]}
        //       cname="inline-block md:mr-2 mr-1"
        //       animation={{
        //         duration: 1000,
        //         delay: 2500,
        //         timingFunction: "ease-out",
        //       }}
        //     >
        //       Banking
        //     </TextAnimation.Slide>
        //   </span>{" "}
        //   for All
        // </>
        <>
          Laying the blocks for <Br on="desktop" />
          the Future of African <Br on="desktop" />
          <span className="text-primary">
            <TextAnimation.Slide
              target="Banking"
              text={["Financial", "Banking"]}
              cname="inline-block md:mr-2 mr-1"
              animation={{
                duration: 1000,
                delay: 2500,
                timingFunction: "ease-out",
              }}
            >
              Banking
            </TextAnimation.Slide>
          </span>{" "}
          Services
        </>
      }
      subheading={
        <>
          We provide streamlined payment services that will stand
          <Br on="tablet" /> the test of time.
        </>
      }
      // button={{
      //   text: "Get in touch",
      //   hasArrow: true,
      //   to: "/get-demo",
      //   size: "medium",
      // }}
      animatedBackground
    />
  );
};

export { HomePageHeader };
